@use "../../style_vars.scss" as *;

.tg-control-button {
    background-color: $main-button-color;
    width: 40%;
    padding: 0.7em;
    color: white;
    border-radius: 1em;
    box-shadow: 2px 3px 3px darken($main-bg-color, 50%);
}

@media (orientation: landscape) {
    .tg-control-button {
        margin: none;
        width: 100%;
        height: 11%;
    }
}
