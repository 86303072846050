.solution-preview {
    display: flex;
    flex-direction: row;
    height: 50%;
    max-width: 100%;
}

@media (orientation: landscape) {
    .solution-preview {
    }
}
