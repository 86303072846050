.status-text {
    height: 50%;
    color: white;
    font-size: normal;
    text-shadow: 1px 1px 1px black;
}

@media (orientation: landscape) {
    .status-text {
        font-size: large;
    }
}
