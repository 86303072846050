@use "../style_vars.scss" as *;

.tg-game {
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/intersection.jpg");
    opacity: 90%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
}
