.intersection {
    position: absolute;
    top: 51%;
    left: 50%;
    width: 30%;
    aspect-ratio: 1;
    min-height: 200px;
    min-width: 200px;
    transform: translate(-50%, -50%);
    transform-origin: center;
}
