.tg-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}

@media (orientation: landscape) {
    .tg-button-group {
        flex-direction: column;
        justify-content: space-between;
    }
}

// .tg-button-group > :last-child {
//   align-self: center;
//   flex-grow: 1;
// }

// @media (orientation: landscape) {
//   .tg-button-group > :last-child {
//     flex-grow: 0;
//   }
// }
