.traffic-light-group {
    aspect-ratio: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    height: 100%;

    grid-template-areas:
        ". top ."
        "left . right"
        ". bottom .";
}

.traffic-light-group > :nth-child(1) {
    width: 100%;
    height: 100%;
    grid-area: top;
}

.traffic-light-group > :nth-child(2) {
    width: 100%;
    height: 100%;
    grid-area: left;
}

.traffic-light-group > :nth-child(3) {
    width: 100%;
    height: 100%;
    grid-area: bottom;
}

.traffic-light-group > :nth-child(4) {
    width: 100%;
    height: 100%;
    grid-area: right;
}
