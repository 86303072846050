@use "../../style_vars.scss" as *;

.status-panel {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 5%;
    left: 8.5%;
    width: 80%;
    height: 25vh;
    background-color: $main-bg-color;
    padding: 1.5%;
    border-radius: 1em;
}

@media (orientation: landscape) {
    .status-panel {
        position: absolute;
        flex-direction: column;
        left: 5%;
        top: 16.67%;
        height: 40vh;
        width: 20vw;
        background-color: $main-bg-color;
        padding: 1.5%;
        border-radius: 1em;
    }
}
