@use "../../style_vars.scss" as *;

.tg-button-container {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 90%;
    height: 27%;
    background-color: $main-bg-color-80-alpha;
    border-radius: 1em;
}

@media (orientation: landscape) {
    .tg-button-container {
        position: absolute;
        right: 5%;
        top: 16.67%;
        height: 66.67%;
        width: 20%;
        background-color: $main-bg-color-80-alpha;
        padding: 1.5%;
        border-radius: 1em;
    }
}
